@import "~react-component-countdown-timer/lib/styles.css";


.vp-center {
    align-items: flex-start !important;
}

html, body, #app, #app > div, li, span, p, input, a, h2, h3, h4, h5, h6, textarea, legend, button, option, div {
    font-family: 'Montserrat', serif !important;
}


/* COUNTDOWN CLOCK */

.event-countdown-timer .countBox {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    background: transparent !important;
    border-radius: 12px;
    padding-left: 4px;
    padding-right: 4px;
    /*backdrop-filter: blur(8px);*/
}

.event-countdown-timer .countBoxItem {
    margin: 0.5rem 0.25rem;
    display: flex;
    flex-direction: column-reverse;
    min-width: 1.9rem;
}


.event-countdown-timer .countBoxItem:last-child {
    margin-right: 0;
}

.event-countdown-timer .countBoxItem:last-child {
    margin-left: 0;
}

.event-countdown-timer .label {
    color: #ffff;
    text-align: center;
    font-size: 10px !important;
    font-family: Arial, system-ui !important;
}

.event-countdown-timer .count {
    background-color: transparent !important;
    text-align: center;
    padding: 0.15rem 0.15rem;
    font-size: 20px;
    min-width: 1.5rem;
    margin-top: 2px;
    border: none !important;
    border-radius: 12px;
    color: #fff !important;
    font-weight: 800;
    font-family: Arial, system-ui !important;
}

@media (max-width: 768px) {
    .event-countdown-timer .countBoxItem {

    }

    .event-countdown-timer .label {
        font-size: 10px !important;
    }

    .event-countdown-timer .count {
        padding: 0 0.25rem;
        font-size: 18px;
        min-width: 1.2rem;
    }
}

/* ----------------- */